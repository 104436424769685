import request from '@/utils/request'
const serivceBase = require('@/config/serviceConfig')

export function exchange(data) {
  return request({
    url: `${serivceBase.ECS}/shipMeal/exchange`,
    method: 'post',
    data
  })
}
// 发送短信
export function getCaptcha(data) {
  return request({
    url: `${serivceBase.ECS}/shipMeal/getCaptcha`,
    method: 'post',
    data
  })
}
// 兑换码列表查询
export function getList(token) {
  return request({
    url: `${serivceBase.ECS}/shipMeal/getDetail/${token}`,
    method: 'post'
  })
}
// 增加平台信息查询接口
export function getPlatInfo(orderNo) {
  return request({
    url: `${serivceBase.ECS}/shipMeal/getPlatInfo/${orderNo}`,
    method: 'get'
  })
}
