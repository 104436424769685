<template>
  <div>
    <div id="loginbox">
      <img src="@/assets/img/YonghuiSync/post.png" />

      <van-form class="lform" ref="form" @submit="onSubmit">
        <van-field
          class="lform-input key"
          placeholder="请输入电商平台订单号"
          v-model="form.orderNo"
          :rules="[
            {
              required: true,
              message: '请输入电商平台订单号'
            }
          ]"
        />
        <van-button class="submit" :loading="loading" native-type="submit"
          >前往兑换</van-button
        >
      </van-form>
      <div class="prompt important">
        <div class="prompt-title">
          <img src="@/assets/img/YonghuiSync/prompt_icon.png" alt="" />
          <span class="text">防骗公告</span>
        </div>
        <div class="content">
          <p class="err">
            兑换后卡券不可退换、不可挂失，请妥善保管卡券信息，切勿发给他人，谨防泄露！
          </p>
          <p>
            本店没有小号旺旺，没有QQ、微信客服，从不刷信誉、做任务，从未委托任何机构或个人以“刷单返利”、“刷信誉”等方式购买商品，凡是要求提供手机验证码、远程共享屏幕都是为了骗你卡密，请广大消费者提高警惕，谨防上当受骗！
          </p>
        </div>
      </div>
      <div class="prompt">
        <div class="prompt-title">
          <img src="@/assets/img/YonghuiSync/tips.png" alt="" />
          <span class="text">领取说明</span>
        </div>
        <div class="content">
          <p>
            1、接收验证码的手机号请填写平台收货手机号，领取后不支持退款！
          </p>
          <p>
            2、如遇兑换量大或网络拥堵，券码出库可能会有所延迟，建议您耐心等待若有疑问可咨询店铺在线客服。
          </p>
          <p>
            3、若需要查看之前已领取的商品，则需要输入之前的订单号以及手机号进行重新验证即可查看。
          </p>
        </div>
      </div>
    </div>
    <div class="overlay" v-show="showDialog">
      <div class="center">
        <img src="@/assets/img/YonghuiSync/warm.png" />
        <h1>温馨提示</h1>
        <div>
          该兑换码已兑换，若需要查看已兑换的券码，请输入
          <span>首次兑换时的手机号码</span>
        </div>
        <button @click="showDialog = false">好的</button>
      </div>
    </div>
    <van-popup
      id="loginbox"
      class="popReset"
      v-model="phoneVisible"
      :close-on-click-overlay="false"
      close-icon="close"
      closeable
    >
      <van-form class="lform popForm" ref="form" @submit="doSubmit">
        <div class="tip" v-if="[1].includes(platId)">
          京东账号名获取方式:打开京东app，进入“我的”，点击“设置”即可看到账号名
        </div>

        <van-field
          class="lform-input buyerAccount"
          placeholder="请输入京东账号名"
          v-model="form.buyerAccount"
          name="buyerAccount"
          v-if="[1].includes(platId)"
          :rules="[
            {
              required: true,
              message: '请输入京东账号名'
            }
          ]"
        />
        <van-field
          class="lform-input phone"
          placeholder="请输入订单收货手机号"
          v-model="form.phone"
          name="phone"
          :rules="[
            {
              required: true,
              validator: (val) => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
              message: '请填写正确的手机号'
            }
          ]"
        />
        <van-field
          class="lform-input yzm"
          placeholder="请输入验证码"
          v-model="form.captchaCode"
          :rules="[
            {
              required: true,
              message: '请输入验证码'
            }
          ]"
        >
          <template #button>
            <div class="cdown" v-if="!time" @click="sendCode">发送验证码</div>
            <van-count-down
              class="cdown"
              v-else
              :time="time"
              @finish="time = 0"
            />
          </template>
        </van-field>
        <van-button class="submit" :loading="loading" native-type="submit">
          前往兑换
        </van-button>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import { exchange, getCaptcha, getPlatInfo } from '@/api/ShipMeal.js'
export default {
  data() {
    return {
      time: 0,
      loading: false,
      showDialog: false,
      phoneVisible: false,
      form: {
        orderNo: undefined,
        phone: null,
        captchaCode: null,
        buyerAccount: undefined
      },
      platId: null
    }
  },
  methods: {
    async onSubmit() {
      const { code, message, data } = await getPlatInfo(this.form.orderNo)
      if (code === 0) {
        this.phoneVisible = true
        this.platId = data.id
      } else {
        this.$toast({ message })
      }
    },
    async doJump(data) {
      // exchangeType 1：卡券，2：直充
      if (data.firstFlag && [2].includes(data.exchangeType)) {
        await this.$dialog.confirm({
          title: '已领取成功',
          message: '充值中，预计10分钟内到账'
        })
        this.$router.push({
          path: '/shipMealList',
          query: { token: data?.token }
        })
      } else {
        this.$router.push({
          path: '/shipMealList',
          query: { token: data?.token }
        })
      }
    },
    sendCode() {
      this.$refs.form.validate('phone').then(async() => {
        const { code, message } = await getCaptcha({
          phone: this.form.phone,
          domain: window.location.hostname
        })
        if (code === 0) {
          this.time = 60000
        } else {
          this.$toast.fail({
            message: message
          })
        }
      })
    },
    async doSubmit() {
      try {
        this.loading = true
        const { code, data, message } = await exchange({
          ...this.form
        })
        this.loading = false
        switch (code) {
          case 0:
            this.doJump(data)
            break
          case 90004:
            this.$router.push({
              path: '/shipMealOutStock'
            })
            break
          case 2020:
            this.$router.push({
              path: '/shipMealCrowded'
            })
            break
          default:
            this.$toast.fail({
              message: message
            })
            break
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/YonghuiSync.less';
.popReset {
  border-radius: 30px !important;
  min-height: auto !important;
  background: #fff !important;
  // position: relative;
  .popForm {
    width: 85vw;
    padding: 100px 40px 60px !important;
    .submit {
      margin: 25px 0 0px;
      border-radius: 44px;
    }
  }
  .tip {
    background: #FFF5E9;
    border-radius: 10px;
    font-size: 22px;
    color: #F93D2D;
    padding: 16px 50px 20px;
    margin-bottom: 15px;
    line-height: 1.5;
  }
}
</style>
